var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',_vm._g({class:[
      'gl-menu-item',
      { fullwidth: _vm.fullwidth },
      { 'gl-menu-item__not-outline': _vm.notOutline },
    ],attrs:{"disabled":_vm.disabled}},_vm.$listeners),[(_vm.badge)?_c('span',{staticClass:"gl-menu-item__badge"},[_vm._v(" "+_vm._s(_vm.badge)+" ")]):_vm._e(),_vm._t("icon",function(){return [(_vm.icon)?_c('gl-icon',{class:{ 'gl-menu-item__icon': _vm.label },attrs:{"disabled":_vm.disabled,"height":_vm.iconBeforeHeight ? _vm.iconBeforeHeight : _vm.iconHeight,"light":_vm.light,"name":_vm.icon,"width":_vm.iconBeforeWidth ? _vm.iconBeforeWidth : _vm.iconWidth}}):_vm._e()]}),_c('span',{staticClass:"gl-menu-item__label",class:[
        { 'gl-menu-item__label--light': _vm.light },
        { 'gl-menu-item__label--warn': _vm.warn },
        { 'gl-menu-item__label--link': _vm.isLink },
      ]},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_vm._t("icon-after",function(){return [(_vm.iconAfter)?_c('gl-icon',{class:{ 'gl-menu-item__icon-after': _vm.label },attrs:{"disabled":_vm.disabled,"height":_vm.iconAfterHeight ? _vm.iconAfterHeight : _vm.iconHeight,"name":_vm.iconAfter,"width":_vm.iconAfterWidth ? _vm.iconAfterWidth : _vm.iconWidth}}):_vm._e()]})],2),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }