var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',_vm._g({class:[
    'gl-icon',
    `gl-icon--${_vm.name}`,
    {
      'gl-icon--light': _vm.light,
      'gl-icon--disabled': _vm.disabled,
      'gl-icon--warning': _vm.warning,
      'gl-icon--dark': _vm.dark,
      'gl-icon--success': _vm.success,
      'gl-icon--failure': _vm.failure,
      'gl-icon--some': _vm.some,
      'icon-fill--white': _vm.white,
      'gl-icon--link': _vm.link,
    },
  ],style:({ 'min-width': `${_vm.width}`, 'min-height': `${_vm.height}` }),attrs:{"height":_vm.height,"width":_vm.width}},_vm.$listeners),[_c('use',{attrs:{"href":_vm.spriteHref}})])
}
var staticRenderFns = []

export { render, staticRenderFns }