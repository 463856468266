import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
//plugins
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import VTooltip from 'v-tooltip'
import VPopover from 'vue-js-popover'
import vClickOutside from 'v-click-outside'
import VueSkeletonLoader from 'skeleton-loader-vue'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import ConfirmationService from 'primevue/confirmationservice'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import VueTheMask from 'vue-the-mask'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// https://www.npmjs.com/package/vue-youtube-embed
import VueYouTubeEmbed from 'vue-youtube-embed'
//utils
import './utils/validation-rules'
//styles
import 'primevue/resources/themes/saga-blue/theme.css' // theme
import 'primevue/resources/primevue.min.css' // core css
import 'primeicons/primeicons.css' // icons

import './assets/styles/main.css'
import { Table, Switch, Pagination } from '@oruga-ui/oruga'
import '@oruga-ui/oruga/dist/oruga.css'
import '@oruga-ui/oruga/dist/oruga-full-vars.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import VueQRCodeComponent from 'vue-qrcode-component'

// CASL
import { abilitiesPlugin } from '@casl/vue'
import defineAbilityFor from './services/defineAbilityFor'
import config from '@/utils/appConfig'

Vue.component('v-select', vSelect)
Vue.component('DatePicker', DatePicker)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

Vue.component('vue-skeleton-loader', VueSkeletonLoader)

Vue.component('qr-code', VueQRCodeComponent)

Vue.use(PrimeVue)
Vue.use(config)
Vue.use(Table)
Vue.use(Pagination)
Vue.use(VueCtkDateTimePicker)
Vue.use(Switch)
Vue.use(VueTheMask)
Vue.use(ToastService)
Vue.use(ConfirmationService)
Vue.use(VueYouTubeEmbed)
Vue.use(vClickOutside)

Vue.use(VTooltip, {
  defaultHtml: false,
})

Vue.use(VPopover, { tooltip: true })

Vue.use(abilitiesPlugin, defineAbilityFor(), {
  useGlobalProperties: true,
})

Vue.prototype.$showPrimeToast = function (
  severity,
  summary,
  detail,
  life = 3000,
) {
  this.$toast.add({ severity, summary, detail, life })
}

Vue.config.productionTip = false

function initApp() {
  new Vue({
    router,
    store,
    render: h => h(App),
    // Hide error with pushing current route
    errorCaptured(err) {
      if (err.name === 'NavigationDuplicated') {
        return false
      }
      return true
    }
  }).$mount('#app')
}

if (localStorage.getItem('token')) {
  store.dispatch('user/initToken').then(() => {
    store.dispatch('user/getMe').then(() => {
      initApp()
    })
  })
} else {
  initApp()
}
