<template>
  <div>
    <transition
      appear
      name="fade"
    >
      <div
        v-if="value"
        class="gl-modal-overlay"
      />
    </transition>
    <transition
      appear
      name="modal"
    >
      <div
        v-if="value"
        key="content"
        ref="modal"
        class="gl-modal"
        @click="closeOnClickaway($event)"
      >
        <div
          class="gl-modal__container"
          :class="[{ 'gl-modal__container--overflow': overflowing }]"
          :style="[{ width: isMobile ? '95vw' : `${width}${ticker}` }]"
        >
          <div
            v-if="loading"
            class="flex align-center justify-center modal-loader"
          >
            <gl-loader />
          </div>
          <div :class="[{ 'gl-modal__container--loading': loading }]">
            <div
              v-if="$slots.header"
              class="gl-modal__header-wrapper"
            >
              <slot name="header" />
            </div>
            <div
              v-else
              class="gl-modal__header-wrapper"
            >
              <div>
                <gl-icon
                  v-if="icon"
                  class="gl-modal__header-icon ml-3 mr-3"
                  :height="iconHeight"
                  :name="icon"
                  :width="iconWidth"
                />
                <h2
                  class="gl-modal__title"
                  :class="{
                    'gl-modal__title--capitalize': capitalizeTitle,
                    'gl-modal__title--width-icon': icon,
                  }"
                >
                  {{ title }}
                </h2>
              </div>
             
              <div
                v-if="closableTop"
                class="gl-modal__close-top"
              >
                <gl-icon
                  class="pointer"
                  :height="16"
                  :hover="false"
                  name="close"
                  :width="16"
                  @click="close"
                />
              </div>
            </div>
            <gl-form
              v-if="!infoOnly"
              v-bind="$attrs"
              actions-wrapper
              :cancel-title="cancelTitle"
              :full-buttons="fullButtons"
              :full-submit="fullSubmit"
              :loading="loading"
              :max-height="maxHeightForm"
              :ok-only="okOnly"
              :overflowing-form="overflowingForm"
              :side-valid="sideValid"
              @cancel="close"
              @submit="$emit('submit')"
            >
              <slot name="mainSlot" />
            </gl-form>
          </div>
          <div
            v-if="$slots.content"
            class="modal-content-wrapper"
          >
            <slot name="content" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//Components
import GlForm from '@/components/gl-form'
import GlLoader from '@/components/gl-loader'
import GlIcon from '@/components/gl-icon'
//Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlForm,
    GlLoader,
    GlIcon,
  },
  mixins: [deviceWidthMixin],
  inheritAttrs: false,
  props: {
    closable: {
      type: Boolean,
      default: false,
    },
    okOnly: {
      type: Boolean,
      default: false,
    },
    sideValid: {
      type: Boolean,
      default: false,
    },
    cancelTitle: {
      type: String,
      default: 'Cancel',
    },
    overflowing: {
      type: Boolean,
      default: false,
    },
    overflowingForm: {
      type: Boolean,
      default: false,
    },
    capitalizeTitle: {
      type: Boolean,
      default: false,
    },
    fullSubmit: {
      type: Boolean,
      default: false,
    },
    fullButtons: {
      type: Boolean,
      default: false,
    },
    infoOnly: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '500',
    },
    ticker: {
      type: String,
      default: 'px',
    },
    maxHeightForm: {
      type: String,
      default: 'unset'
    },
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    iconHeight: {
      type: Number,
      default: 16,
    },
    iconWidth: {
      type: Number,
      default: 16,
    },
    closableTop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    closeOnClickaway(e) {
      if (this.closable && e.target === this.$refs.modal) this.close()
    },
  },
}
</script>

<style>
.modal-enter-active,
.modal-leave-active {
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.3s linear;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.3) translateY(-50%);
}
</style>
